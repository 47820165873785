import api from './index';

const getServidores = (page, itemsPerPage, filtrosAplicados = [], tipoPesquisa) => api.get(
    'servidores', 
    {params: {current_page: page, limit: itemsPerPage, filtros: filtrosAplicados, tipoPesquisa: tipoPesquisa}}
);

const getServidor = (id_servidor) => api.get(
    'servidores/' + id_servidor
);

const getServidorInabilitado = (id_servidor) => api.get(
    'servidor/inabilitado/' + id_servidor
);

const getTotalServidores = (filtrosAplicados = [], tipoPesquisa) => api.get(
    'servidores-total', 
    {params: {filtros: filtrosAplicados, tipoPesquisa: tipoPesquisa}}
);

const getServidorById = (id_servidor) => api.get(
    'servidores/id/' + id_servidor
);

const getServidorBySiape = (nr_siape) => api.get(
    'servidores/siape/' + nr_siape
);

const getServidorByCPF = (nr_cpf) => api.get(
    'servidores/cpf/' + nr_cpf.replaceAll('.','').replaceAll('-','')
);

const getServidorByCpfForGestor = (nr_cpf) => api.get(
    'getServidorByCpfForGestor/cpf/' + nr_cpf.replaceAll('.','').replaceAll('-','')
);

const updateServidor = (id_servidor, servidor) => api.put(
    'servidores/' + id_servidor, servidor
);

const getServidorExternoByCPF = (nr_cpf) => api.get(
    'servidorExterno/cpf/' + nr_cpf.replaceAll('.','').replaceAll('-','')
);

const enviarCedidoRequisitado = (avaliador) => api.post(
    'ServidorExterno', 
    avaliador
);

const enviarAvaliados = (avaliados) => api.post(
    'EquipeTrabalhoExterno', 
    avaliados
);

const getEquipeTrabalhoExternoByServidor = ($id_servidor) => api.get(
    'equipes_trabalho_externo/id-ciclo/' + $id_servidor
);

const getCargoServidor = () => api.get(
    'getCargoServidor'
);

const getOrgaosRequisitante = () => api.get(
    'getOrgaosRequisitante'
);

const getServidorOrgaoRequisitante = (cdOrgaoRequisitante) => api.get(
    'getServidorOrgaoRequisitante/' + cdOrgaoRequisitante
);

const getDetalheServidor = (id_servidor) => api.get(
    'getDetalheServidor/' + id_servidor
);

const getServidorAvaliacaoCadastradaByCPF = (nr_cpf) => api.get(
    'getServidorAvaliacaoCadastradaByCPF/' + nr_cpf.replaceAll('.','').replaceAll('-','')
);

const getGestorBySiape = (nr_siape) => api.get(
    'getGestorBySiape/' + nr_siape
);

const getGestorByCPF = (nr_cpf) => api.get(
    'getGestorByCPF/' + nr_cpf.replaceAll('.','').replaceAll('-','')
);

const gerarExtracaoRelatorioServidores = (page, itemsPerPage, filtrosAplicados = [], tipoPesquisa) => api.get(
    'gerarExtracaoRelatorioServidores', 
    { params: {current_page: page, limit: itemsPerPage, filtros: filtrosAplicados, tipoPesquisa: tipoPesquisa}, responseType: 'blob' } 
);

const getServidorWithOrgao = (id_servidor) => api.get(
    'getServidorWithOrgao/' + id_servidor
);


export {
    getServidores,
    getServidor,
    getServidorById,
    getServidorBySiape,
    getServidorByCPF,
    updateServidor,
    getTotalServidores,
    getServidorInabilitado,
    getServidorExternoByCPF,
    enviarCedidoRequisitado,
    enviarAvaliados,
    getEquipeTrabalhoExternoByServidor,
    getCargoServidor,
    getOrgaosRequisitante,
    getServidorOrgaoRequisitante,
    getDetalheServidor,
    getServidorAvaliacaoCadastradaByCPF,
    getGestorBySiape,
    getGestorByCPF,
    gerarExtracaoRelatorioServidores,
    getServidorWithOrgao,
    getServidorByCpfForGestor
}