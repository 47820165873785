<template>
<v-form ref="formNotificacaoUnidade">
    <div>
        <h1>Cadastrar notificação para unidade de gestão de pessoas</h1>
        <v-col cols="12">
            <v-card>
                <v-row dense class="ma-0 pa-2">
                    <v-col md="7" cols="12" class="mx-0 px-3">
                        <v-combobox
                            id="orgao"
                            ref="orgao"
                            :items="orgaos"
                            v-model="orgaoRequisitante"
                            hide-details
                            outlined 
                            dense
                            label="Órgão requisitante do avaliado"
                            placeholder="Órgão requisitante do avaliado"
                            v-on:change="carregarServidorOrgao(),carregarEmail()"
                            :rules="validaCampo"
                            ></v-combobox>
                    </v-col>
                    <v-col cols="12" md="5" class="mx-0 pt-0 float-right">
                        <template>
                            <v-radio-group 
                                v-model="aderiuAoAvalia" 
                                row 
                                class="float-right" 
                                id="aderiuAoAvalia"
                                v-on:change="carregarServidorOrgao(),carregarEmail()"
                            >
                                <template v-slot:label>
                                    <div>O órgão aderiu ao AvaliaMGI?</div>
                                </template>
                                <v-radio value="nao">
                                    <template v-slot:label>
                                    <div><strong >NÃO</strong></div>
                                    </template>
                                </v-radio>
                                <v-radio value="sim">
                                    <template v-slot:label>
                                    <div><strong >SIM</strong></div>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </template>
                    </v-col>
                </v-row>
                <v-row v-show="tableServidor" dense class="ma-0 pa-2">
                    <v-col cols="12" class="mx-0 px-3">
                        Servidores cedidos/requisitados do órgão
                    </v-col>
                    <v-col cols="12" class="mx-0 px-3">
                        <v-data-table
                            :headers="headers"
                            :items="servidores"
                            @update:options="carregarServidorOrgao"
                            class="elevation-1"
                        >
                        <template v-slot:[`item.nm_unidade`]="{ item }">
                            <v-row  v-if="item.nm_unidade">
                                <router-link :to="{ name: 'planos-trabalho-detalhar', params: { id: item.id_plano_trabalho } }" style="text-decoration: none">
                                    {{item.nm_unidade}}
                                </router-link>
                            </v-row>
                        </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-row dense class="ma-0 pa-2">
                    <v-col cols="12" class="mx-0 px-3">
                        <v-text-field id="nmUnidadeGestao" ref="nmUnidadeGestao" v-model="nm_unidade_gestao" dense hide-details="auto" label="Nome da unidade de gestão de pessoas" outlined clearable clear-icon="mdi-close-circle-outline" :rules="validaCampo"></v-text-field>
                    </v-col>
                </v-row>
                <v-row dense class="ma-0 pa-2">
                    <v-col cols="7" class="mx-0 px-3">
                        <v-text-field id="emailUnidade" ref="emailUnidade" v-model="ds_email" dense hide-details="auto" label="Email da unidade de gestão de pessoas " outlined clearable clear-icon="mdi-close-circle-outline" :rules="validaCampo"></v-text-field>
                    </v-col>
                    <v-col cols="5" class="mx-0 px-3">
                        <v-text-field id="telefoneUnidade" ref="telefoneUnidade" v-model="nr_telefone" dense hide-details="auto" label="Telefone da unidade de gestão de pessoas" v-mask="'(##) ####-#####'" outlined clearable clear-icon="mdi-close-circle-outline" :rules="validaCampo"></v-text-field>
                    </v-col>
                </v-row>
                <v-row dense class="ma-0 pa-2">
                    <v-col cols="12" class="mx-0 px-3">
                        <v-text-field id="tituloNotificacao" ref="tituloNotificacao" v-model="tx_assunto" dense hide-details="auto" label="Título do email" outlined clearable clear-icon="mdi-close-circle-outline" :rules="validaCampo"></v-text-field>
                    </v-col>
                </v-row>
                <v-row dense class="ma-0 pa-2">
                    <v-col cols="12" class="mx-0 px-3">
                        Corpo do email
                    </v-col>
                    <v-col cols="12" class="mx-0 px-3">
                        <ckeditor :editor="editor" v-model="tx_corpo" :config="editorConfig" :rules="validaCampo" ref="tx_corpo"></ckeditor>
                    </v-col>
                </v-row>
                <v-row dense class="ma-0 pa-2">
                    <v-col cols="7" class="mx-0 px-3">
                        <v-file-input id="anexoNotificacao" ref="anexoNotificacao" v-model="anexo" label="Anexar ofício" outlined dense accept=".pdf" :rules="validaCampo"></v-file-input>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="10" class="text-right"></v-col>
                    <v-col cols="2" class="ma-0 mb-4 pa-2 px-8 d-flex flex-row justify-end align-end text-right elevation-0">
                        <v-btn id="btnSalvar" color="success" @click="enviarDados()">
                            Salvar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </div>

    <v-dialog v-model="dialogError" width="570" >
        <v-card>
            <v-card-title class="text-h5 text-center">
                <v-alert prominent type="warning" outlined width="100%">
                    <v-row align="center">
                        <v-col class="grow">{{msgDialog}}</v-col>
                        <v-col class="shrink">
                        <v-btn color="warning"  @click="dialogError = false">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog>
</v-form>
</template>
<script>
    import Vue from 'vue';
    import CKEditor from '@ckeditor/ckeditor5-vue2';

    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

    Vue.use( CKEditor );

    import { getOrgaosRequisitante, getServidorOrgaoRequisitante } from '../api/servidor';
    import { createNotificacaoUnidadeGestao, getTituloCorpoPadrao } from '../api/notificacao_unidade_gestao';

    export default {
        name: 'NotificacaoUnidadeGestaoCadastrar',
        components: {
            ckeditor: CKEditor.component
        },
        data() {
            return {
                dialogError : false,
                msgDialog: '',
                validaCampo: [
                    v => !!v || 'Campo obrigatório',
                ],
                
                editor: ClassicEditor,
                tx_corpo: '',
                editorConfig: {
                    language: 'pt-br'
                },

                orgaos: [],
                
                orgaoRequisitante: '',
                nm_unidade_gestao: '',
                ds_email: '',
                nr_telefone: '',
                tx_assunto: '',
                anexo: null,

                servidores: [],
                tableServidor: false,
                headers: [],
                aderiuAoAvalia: 'nao'
            };
        },
        mounted() {
            getOrgaosRequisitante()
                .then(response => { 
                    this.orgaos = response.data.data; 
                    this.orgaos.map(orgao =>{
                        orgao.text = orgao.nm_orgao_requisitante
                        orgao.value = orgao.cd_orgao_requisitante
                    })
                });
        },
        methods: {
            carregarServidorOrgao() {
                if ( this.orgaoRequisitante.value !== undefined ) {
                    getServidorOrgaoRequisitante(this.orgaoRequisitante.value)
                        .then(response => {
                            this.servidores = response.data.data;
                            this.tableServidor = true;

                            this.headers = [
                                { text: 'Nome do avaliado', value: 'nm_servidor', sortable: false },
                                { text: 'CPF do avaliado', value: 'nr_cpf', sortable: false },
                                { text: 'Cargo do avaliado', value: 'nm_cargo', sortable: false },
                                { text: 'Gratificação do avaliado', value: 'sg_gratificacao', sortable: false },
                                { text: 'Plano de trabalho', value: 'nm_unidade', sortable: false },
                            ];
                        });
                } else {
                    this.tableServidor = false;
                }
            },

            carregarEmail() {
                if ( this.orgaoRequisitante.value !== undefined ) {
                    let data = new FormData();
                    data.append('cd_orgao_requisitante', this.orgaoRequisitante.value);
                    data.append('aderiuAoAvalia', this.aderiuAoAvalia);
                
                    getTituloCorpoPadrao(data)
                        .then(response => { 
                            this.tx_assunto = response.data.data.tx_assunto; 
                            this.tx_corpo = response.data.data.tx_corpo; 
                        });
                } else {
                    this.tx_assunto = ''; 
                    this.tx_corpo = '';
                }
            },

            enviarDados () {
                if ( !this.tx_corpo ) {
                    this.msgDialog = 'Corpo do email deve ser preenchido';
                    return this.dialogError = true;
                } else if ( this.$refs['formNotificacaoUnidade'].validate() ) {
                    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.ds_email) == false) {
                        this.msgDialog = 'E-mail da unidade de gestão de pessoas não é válido';
                        return this.dialogError = true;
                    }

                    let data = new FormData();
                    data.append('cd_orgao_requisitante', this.orgaoRequisitante.value);
                    data.append('nm_unidade_gestao', this.nm_unidade_gestao);
                    data.append('ds_email', this.ds_email);
                    data.append('nr_telefone', this.nr_telefone);
                    data.append('tx_assunto', this.tx_assunto);
                    data.append('tx_corpo', this.tx_corpo);
                    data.append('file', this.anexo);
                    createNotificacaoUnidadeGestao(data)
                        .then(response => {
                            this.$vuetify.goTo(0);
                            this.$router.push(
                                {
                                    name: 'Unidade de gestão de pessoas',
                                },
                                () => this.$store.dispatch('alertas/show', {
                                    tipo: 'Sucesso',
                                    titulo: response.data.message,
                                })
                            );
                        })
                        .catch(erro => {
                            this.$vuetify.goTo(0);
                            this.$router.push(
                                {
                                    name: 'Unidade de gestão de pessoas',
                                },
                                () => this.$store.dispatch('alertas/show', {
                                    tipo: 'Erro',
                                    titulo: 'Erro ao cadatrar notificação de unidade de gestão de pessoas.',
                                })
                            );
                        });
                }
            },
        }
    }
</script>
